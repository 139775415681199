import './Logout.css'

const Logout = () => {
  return (
    <div className={'logoutMessage'}>
      You have been logged out
      <br />
      <a href={'/'}>Click here to login again</a>
    </div>
  )
}

export default Logout
