import React, { Suspense } from 'react'
import './App.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import { ModalProvider } from './common/modal/modalContext'
import Logout from './logout/Logout'
import packageJson from '../package.json'
global.appVersion = packageJson.version

// Lazy loading of home introduced to prevent websocket being started before redirect to authentication (bodge?)
const Home = React.lazy(() => import('./home/Home'))

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

const oktaAuth = new OktaAuth({
  issuer: 'https://auth.sentosashipbrokers.com/oauth2/default',
  clientId: window.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
})

// TODO: Add spinner in home suspense fallback component
export default function App() {
  return (
    <Router>
      <Security oktaAuth={oktaAuth}>
        <SecureRoute exact path="/">
          <Suspense fallback={''}>
            <ModalProvider>
              <Home />
            </ModalProvider>
          </Suspense>
        </SecureRoute>
        <SecureRoute exact path="/board/:boardId">
          <Suspense fallback={''}>
            <ModalProvider>
              <Home />
            </ModalProvider>
          </Suspense>
        </SecureRoute>
        <SecureRoute exact path="/board/:boardId/route/:boardRouteId">
          <Suspense fallback={''}>
            <ModalProvider>
              <Home />
            </ModalProvider>
          </Suspense>
        </SecureRoute>
        <Route exact path="/login/callback">
          <LoginCallback
            errorComponent={
              'There was an error logging in. You most likely have not been put in the correct group in Okta. Please contact Steve.Harrison@sentosashipbrokers.com.'
            }
          />
        </Route>
        <Route exact path="/logout" component={Logout} />
      </Security>
    </Router>
  )
}
