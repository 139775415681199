import React from 'react'

const useModal = () => {
  let [modal, setModal] = React.useState(false)
  let [modalContent, setModalContent] = React.useState('I’m the Modal Content')
  let [modalTitle, setModalTitle] = React.useState('')

  let handleModal = (content = false, title = '') => {
    setModal(!modal)
    if (content) {
      setModalTitle(title)
      setModalContent(content)
    }
  }

  return { modal, handleModal, modalContent, modalTitle }
}

export default useModal
