import React from 'react'
import ReactDOM from 'react-dom'
import { ModalContext } from './modalContext'

const Modal = () => {
  let { modalContent, modalTitle, handleModal, modal } = React.useContext(ModalContext)
  if (modal) {
    return ReactDOM.createPortal(
      <>
        <div className="modal__overlay" onClick={() => handleModal()}></div>
        <div className="modal" role="dialog">
          <div className={'modal__header'}>
            {modalTitle && (
              <div className={'modal__headerTitle'} style={{ width: '95%' }}>
                <h1>{modalTitle}</h1>
              </div>
            )}
            <div className={'modal__close-btn'}>
              <input
                type={'image'}
                alt="Close modal window"
                onClick={() => handleModal()}
                onMouseOver={e => (e.currentTarget.src = process.env.PUBLIC_URL + '/icons/Close.svg')}
                onMouseOut={e => (e.currentTarget.src = process.env.PUBLIC_URL + '/icons/Close1.w.svg')}
                src={process.env.PUBLIC_URL + '/icons/Close1.w.svg'}
              />
            </div>
          </div>
          <div className="modal__inner">{modalContent}</div>
        </div>
      </>,
      document.querySelector('#modal-root')
    )
  } else return null
}

export default Modal
