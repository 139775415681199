import React from 'react'
import useModal from './useModal'
import Modal from './Modal'

let ModalContext
let { Provider } = (ModalContext = React.createContext())

let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent, modalTitle } = useModal()
  return (
    <Provider
      value={{
        handleModal,
        modal,
        modalTitle,
        modalContent,
      }}
    >
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
